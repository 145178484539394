import React, { useState, useEffect, useCallback } from "react"
import Layout from "../../../components/common/layout"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import SeminarDates from "../../../static/utils/onlineSeminarDates.json"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import { BGLinearGradient } from "../../../components/common/styledComponentsHelper"

export const Head = () => (
  <>
    <title>
      Live Online Seminar Bierdeckelkalkulation Datum | Fachhandwerk360
    </title>
    <meta name="description" content="Alles was ein Handwerker braucht" />
    <meta
      name="keywords"
      content="Kalkulation, Beratung, Digitalisierung, Handwerk, Software, kaufmännischer
      Begleiter Bauhandwerk"
    />
  </>
)

const ChooseDate = () => {
  const [showMore, setShowMore] = useState(false)
  const [showNextStep, setShowNextStep] = useState(false)
  const [numberArray, setNumberArray] = useState([])
  const [active, setActive] = useState(-1)
  const [date, setDate] = useState("")
  const [time, setTime] = useState("")
  const [type, setType] = useState("")
  const variants = {
    show: { opacity: 1 },
    hide: { opacity: 0 },
  }
  const LinkMotion = motion(Link)
  const currentDate = new Date()

  const filteredSeminarDates = SeminarDates.filter(seminar => {
    const seminarDate = new Date(seminar.date.split(".").reverse().join("-")) // Convert "dd.mm.yyyy" to "yyyy-mm-dd"
    return seminarDate >= currentDate
  })

  const randNumber = useCallback(() => {
    let min = Math.ceil(3)
    let max = Math.floor(18)
    let randArray = []
    for (let i = 0; i < filteredSeminarDates.length; i++) {
      randArray.push(Math.floor(Math.random() * (max - min) + min))
    }
    setNumberArray(randArray)
  }, [filteredSeminarDates.length])

  const checkDate = eventDate => {
    const today = new Date()
    const eventDateParts = eventDate.split(".")
    let newEventDate = new Date(
      +eventDateParts[2],
      eventDateParts[1] - 1,
      +eventDateParts[0]
    )

    if (newEventDate > today) {
      return true
    } else {
      return false
    }
  }

  const handleButtonClick = (eventData, selectionNumber) => {
    setActive(selectionNumber)
    setShowNextStep(true)
    setDate(eventData.date)
    setTime(eventData.time)
    setType(eventData.type)
  }

  useEffect(() => {
    randNumber()
  }, [randNumber])

  return (
    <Layout>
      <BGLinearGradient>
        {filteredSeminarDates.length > 0 ? (
          <>
            <div className="display-4 fw-bold text-center text-light p-5">
              Bitte wählen Sie einen Termin aus
            </div>
            <ListContainer className="container p-4">
              {filteredSeminarDates.slice(0, 10).map((event, i) => {
                if (checkDate(event.date) && event.available === "1") {
                  return (
                    <motion.div
                      initial={{ y: 50, opacity: 0 }}
                      animate={{
                        y: 0,
                        opacity: 1,
                      }}
                      transition={{
                        delay: 0.1 * i,
                        type: "spring",
                        damping: 30,
                        stiffness: 300,
                      }}
                      className="row w-100"
                      key={event.date}
                    >
                      <a
                        className={
                          active === i
                            ? "row bg-primary border border-success border-3 align-items-center rounded-pill text-light text-center fw-bold fs-5 p-2 m-2 mb-4 text-decoration-none"
                            : "row bg-primary border border-dark border-3 align-items-center rounded-pill text-light text-center fw-bold fs-5 p-2 m-2 mb-4 text-decoration-none"
                        }
                        onClick={() => handleButtonClick(event, i)}
                        href="#weiter"
                      >
                        <div className="col-3 p-0">
                          <StaticImage
                            src="../../../images/event/logo-rund.png"
                            layout="fixed"
                            width={75}
                            quality={95}
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Fachhandwerk360 Logo rund"
                            placeholder="none"
                            className="rounded"
                          />
                        </div>
                        <div className="col-9">
                          {event.date} | {event.time} Uhr
                          <div className="text-success fw-light fs-6 ">
                            Noch {numberArray[i]} Plätze frei!
                          </div>
                        </div>
                      </a>
                    </motion.div>
                  )
                } else {
                  return (
                    <motion.div
                      key={event.date}
                      className="row bg-gray border border-dark border-3 align-items-center rounded-pill text-light text-center fw-bold fs-5 p-2 m-2 mb-4"
                      initial={{ y: 50, opacity: 0 }}
                      animate={{
                        y: 0,
                        opacity: 0.5,
                      }}
                      transition={{
                        delay: 0.1 * i,
                        type: "spring",
                        damping: 30,
                        stiffness: 300,
                      }}
                    >
                      <div className="col-3 p-0">
                        <StaticImage
                          src="../../../images/event/logo-rund_gray.png"
                          layout="fixed"
                          width={75}
                          quality={95}
                          formats={["AUTO", "WEBP", "AVIF"]}
                          alt="Fachhandwerk360 Logo rund"
                          placeholder="none"
                          className="rounded"
                        />
                      </div>
                      <div className="col-9 text-light-gray">
                        {event.date} | {event.time} Uhr
                        <div className="text-light fw-normal fs-6 ">
                          {checkDate(event.date)
                            ? "Leider ausgebucht."
                            : "Leider schon vorbei."}
                        </div>
                      </div>
                    </motion.div>
                  )
                }
              })}
              {showMore &&
                filteredSeminarDates.slice(10).map((event, i) => {
                  if (checkDate(event.date) && event.available === "1") {
                    return (
                      <motion.div
                        initial={{ y: 50, opacity: 0 }}
                        animate={{
                          y: 0,
                          opacity: 1,
                        }}
                        transition={{
                          delay: 0.1 * i,
                          type: "spring",
                          damping: 30,
                          stiffness: 300,
                        }}
                        className="row w-100"
                        key={event.date}
                      >
                        <a
                          className={
                            active === 9 + i //count from 9 because of the first map-array
                              ? "row bg-primary border border-success border-3 align-items-center rounded-pill text-light text-center fw-bold fs-5 p-2 m-2 mb-4 text-decoration-none"
                              : "row bg-primary border border-dark border-3 align-items-center rounded-pill text-light text-center fw-bold fs-5 p-2 m-2 mb-4 text-decoration-none"
                          }
                          onClick={() => handleButtonClick(event, 9 + i)} //count from 9 because of the first map-array
                          href="#weiter"
                        >
                          <div className="col-3 p-0">
                            <StaticImage
                              src="../../../images/event/logo-rund.png"
                              layout="fixed"
                              width={75}
                              quality={95}
                              formats={["AUTO", "WEBP", "AVIF"]}
                              alt="Fachhandwerk360 Logo rund"
                              placeholder="none"
                              className="rounded"
                            />
                          </div>
                          <div className="col-9">
                            {event.date} | {event.time} Uhr
                            <div className="text-success fw-light fs-6 ">
                              Noch {numberArray[i]} Plätze frei!
                            </div>
                          </div>
                        </a>
                      </motion.div>
                    )
                  } else {
                    return (
                      <motion.div
                        key={event.date}
                        className="row bg-gray border border-dark border-3 align-items-center rounded-pill text-light text-center fw-bold fs-5 p-2 m-2 mb-4"
                        initial={{ y: 50, opacity: 0 }}
                        animate={{
                          y: 0,
                          opacity: 0.5,
                        }}
                        transition={{
                          delay: 0.1 * i,
                          type: "spring",
                          damping: 30,
                          stiffness: 300,
                        }}
                      >
                        <div className="col-3 p-0">
                          <StaticImage
                            src="../../../images/event/logo-rund_gray.png"
                            layout="fixed"
                            width={75}
                            quality={95}
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Fachhandwerk360 Logo rund"
                            placeholder="none"
                            className="rounded"
                          />
                        </div>
                        <div className="col-9 text-light-gray">
                          {event.date} | {event.time} Uhr
                          <div className="text-light fw-normal fs-6 ">
                            {checkDate(event.date)
                              ? "Leider ausgebucht."
                              : "Leider schon vorbei."}
                          </div>
                        </div>
                      </motion.div>
                    )
                  }
                })}
              {Object.keys(filteredSeminarDates).length > 10 && !showMore ? (
                <button
                  className="btn btn-success px-4 py-2 mx-auto d-block text-dark m-3"
                  onClick={() => setShowMore(true)}
                >
                  Mehr anzeigen
                </button>
              ) : (
                ""
              )}
              {showNextStep && (
                <LinkMotion
                  id="weiter"
                  initial={{ opacity: 0 }}
                  animate={showNextStep ? "show" : "hide"}
                  variants={variants}
                  className="btn btn-success w-75 px-4 py-2 mx-auto d-block text-dark m-3"
                  state={{ date: date, time: time, type: type }}
                  to="/online-seminar/organic/anmeldung"
                >
                  Weiter
                </LinkMotion>
              )}
            </ListContainer>
          </>
        ) : (
          <div
            className="display-4 fw-bold text-center text-light p-5"
            style={{ height: "85vh" }}
          >
            Aktuell gibt es keine Seminare
            <Link
              to="/"
              className="mt-5 w-25 btn btn-success px-4 py-2 mx-auto d-block text-dark m-3"
              style={{ minWidth: "160px", whiteSpace: "pre" }}
            >
              zur Startseite
            </Link>
          </div>
        )}
      </BGLinearGradient>
    </Layout>
  )
}

const ListContainer = styled.div`
  max-width: 485px !important;
`

export default ChooseDate
